import React, { useEffect, useState } from 'react';

function Snackbar({ message }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 animate-fade-in-up">
      <div className="bg-gray-800 text-white px-6 py-3 rounded-lg shadow-lg min-w-[300px] max-w-[500px]">
        <p className="text-center">{message}</p>
      </div>
    </div>
  );
}

export default Snackbar;
