import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';  // Updated import path

export const blogPosts = [
  {
    id: 1,
    title: "Welcome to MAGMA SENDER",
    excerpt: "Overview of MAGMA SENDER's evolution and current features for cryptocurrency and blockchain operations.",
    date: "2024-09-13",
    slug: "welcome-to-magma-sender"
  },
  {
    id: 2,
    title: "What is a Crypto Airdrop?",
    excerpt: "Learn about crypto airdrops, their types, benefits, and how to participate or create one using MAGMA SENDER.",
    date: "2023-06-15",
    slug: "what-is-a-crypto-airdrop"
  }
];

function Blog() {
  return (
    <div className="min-h-screen bg-gray-950 text-gray-300">
      <Header />
      <div className="max-w-4xl mx-auto p-4">
        <h1 className="text-4xl font-bold text-purple-300 mb-8">MAGMA SENDER Blog</h1>
        <div className="space-y-8">
          {blogPosts.map(post => (
            <div key={post.id} className="bg-gray-800 rounded-lg p-6 shadow-lg">
              <h2 className="text-2xl font-semibold text-purple-300 mb-2">
                <Link to={`/blog/${post.slug}`} className="hover:underline">{post.title}</Link>
              </h2>
              <p className="text-gray-400 mb-4">{post.excerpt}</p>
              <p className="text-sm text-gray-500">{post.date}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Blog;