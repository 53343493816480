/* global BigInt */

import React, { useState, useCallback, useEffect } from 'react';
import { Alchemy, Network } from 'alchemy-sdk';
import { formatEther } from 'ethers';
import Snackbar from './Snackbar';
import { ReactComponent as LogoSVG } from './logo.svg';

const settings = {
  apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
  network: Network.ETH_MAINNET,
};
const alchemy = new Alchemy(settings);

function App() {
  const [address, setAddress] = useState('');
  const [transactions, setTransactions] = useState(null);
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState('blockNum');
  const [sortDirection, setSortDirection] = useState('desc');
  const [addressAge, setAddressAge] = useState(null);
  const [tokens, setTokens] = useState(null);
  const [nftCount, setNftCount] = useState(0);
  const [statusMessages, setStatusMessages] = useState([]);
  const [ensCache, setEnsCache] = useState({});
  const [analysisResult, setAnalysisResult] = useState(null);
  const [analysisComplete, setAnalysisComplete] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const itemsPerPage = 20;

  const addStatusMessage = useCallback((message) => {
    setSnackbarMessage(message);
  }, []);

  const validateAddress = (addr) => {
    return (addr.length === 42 && addr.startsWith('0x')) || addr.length === 44 || addr.endsWith('.eth');
  };

  const hexToDecimal = (hex) => {
    if (hex === undefined || hex === null) return null;
    return parseInt(hex, 16);
  };

  const formatTransactions = (txs, userAddress) => {
    return txs.map(tx => {
      console.log('Transaction value:', tx.value);
      let formattedValue;
      try {
        if (tx.value !== null && tx.value !== undefined) {
          formattedValue = parseFloat(tx.value);
        } else {
          formattedValue = null;
        }
      } catch (error) {
        console.error('Error formatting transaction value:', error);
        formattedValue = null;
      }

      return {
        ...tx,
        blockNum: tx.blockNum ? parseInt(tx.blockNum, 16) : null,
        direction: tx.from && tx.from.toLowerCase() === userAddress.toLowerCase() ? 'outgoing' : 'incoming',
        value: formattedValue
      };
    });
  };

  const calculateStats = async (txs, nftCount, address) => {
    console.log('First transaction:', txs[0]);
    const totalTx = txs.length;
    const incomingTx = txs.filter(tx => tx.direction === 'incoming').length;
    const outgoingTx = totalTx - incomingTx;
    
    const formatValue = (value) => {
      if (value !== null && value !== undefined) {
        return parseFloat(value);
      }
      return 0;
    };

    const totalEthIn = txs.filter(tx => tx.direction === 'incoming' && tx.asset === 'ETH')
      .reduce((sum, tx) => sum + formatValue(tx.value), 0);
    const totalEthOut = txs.filter(tx => tx.direction === 'outgoing' && tx.asset === 'ETH')
      .reduce((sum, tx) => sum + formatValue(tx.value), 0);

    // Fetch current ETH balance
    const balance = await alchemy.core.getBalance(address);
    const currentEthBalance = parseFloat(safeFormatEther(balance));

    // Calculate rough profit/loss
    const roughProfitLoss = currentEthBalance + totalEthOut - totalEthIn;

    // Calculate profit/loss percentage
    let profitLossPercentage;
    if (totalEthIn > 0) {
      profitLossPercentage = ((roughProfitLoss / totalEthIn) * 100).toFixed(2);
    } else if (roughProfitLoss > 0) {
      profitLossPercentage = '∞'; // Infinite profit if no ETH in but there's profit
    } else if (roughProfitLoss < 0) {
      profitLossPercentage = '-∞'; // Infinite loss if no ETH in but there's loss
    } else {
      profitLossPercentage = '0.00';
    }

    return {
      totalTransactions: totalTx,
      incomingTransactions: incomingTx,
      outgoingTransactions: outgoingTx,
      totalEthIn: totalEthIn.toFixed(4),
      totalEthOut: totalEthOut.toFixed(4),
      currentEthBalance: currentEthBalance.toFixed(4),
      nftTransactions: nftCount,
      roughProfitLoss: roughProfitLoss.toFixed(4),
      profitLossPercentage: profitLossPercentage
    };
  };

  const getEtherscanLink = (type, value) => {
    const baseUrl = 'https://etherscan.io';
    switch (type) {
      case 'transaction':
        return `${baseUrl}/tx/${value}`;
      case 'address':
        return `${baseUrl}/address/${value}`;
      case 'block':
        return `${baseUrl}/block/${value}`;
      default:
        return '#';
    }
  };

  const sortTransactions = (txs) => {
    return txs.sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const paginatedTransactions = transactions ? 
    sortTransactions([...transactions]).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) 
    : [];

  const pageCount = transactions ? Math.ceil(transactions.length / itemsPerPage) : 0;

  const fetchEarliestBlockTimestamp = async (transactions) => {
    if (transactions.length === 0) return null;
    
    const earliestBlockNum = Math.min(...transactions.map(tx => tx.blockNum));
    try {
      const block = await alchemy.core.getBlock(earliestBlockNum);
      return block.timestamp;
    } catch (error) {
      console.error('Error fetching earliest block:', error);
      return null;
    }
  };

  const calculateAddressAge = (timestamp) => {
    const now = Math.floor(Date.now() / 1000);
    const ageInSeconds = now - timestamp;
    const days = Math.floor(ageInSeconds / 86400);
    const hours = Math.floor((ageInSeconds % 86400) / 3600);
    const minutes = Math.floor((ageInSeconds % 3600) / 60);
    return `${days}d ${hours}h ${minutes}m`;
  };

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const resolveEns = useCallback(async (ensName) => {
    if (ensCache[ensName]) {
      return ensCache[ensName];
    }

    await delay(1000); // Add a delay to help with rate limiting
    const resolvedAddress = await alchemy.core.resolveName(ensName);
    
    if (resolvedAddress) {
      setEnsCache(prev => ({ ...prev, [ensName]: resolvedAddress }));
    }

    return resolvedAddress;
  }, [ensCache]);

  const fetchTransactions = async () => {
    setIsLoading(true);
    setError(null);
    setStatusMessages([]);
    setAnalysisComplete(false);
    setAnalysisResult(null); // Clear previous analysis result
    setStats(null); // Clear previous stats
    setAddressAge(null); // Clear previous address age
    setNftCount(0); // Reset NFT count

    const MAX_TRANSACTIONS = 20000;
    const BATCH_SIZE = 1000;
    const MAX_RETRIES = 5;
    const INITIAL_BACKOFF = 1000; // 1 second

    const fetchWithRetry = async (fetchFunction, retryCount = 0) => {
      try {
        const result = await fetchFunction();
        await delay(1000); // Add a 1-second delay between successful requests
        return result;
      } catch (error) {
        if (error.message.includes('429') || error.message.includes('timed out')) {
          if (retryCount < MAX_RETRIES) {
            const backoffTime = INITIAL_BACKOFF * Math.pow(2, retryCount);
            addStatusMessage(`Rate limit hit. Retrying in ${backoffTime / 1000} seconds... (Attempt ${retryCount + 1}/${MAX_RETRIES})`);
            await delay(backoffTime);
            return fetchWithRetry(fetchFunction, retryCount + 1);
          }
        }
        throw error;
      }
    };

    try {
      if (!address || !validateAddress(address)) {
        throw new Error("Please enter a valid Ethereum address or ENS name");
      }

      addStatusMessage("Initializing quantum entanglement with Ethereum network...");
      await delay(1000);

      let resolvedAddress = address;
      if (address.endsWith('.eth')) {
        addStatusMessage("Resolving ENS name...");
        resolvedAddress = await resolveEns(address);
        if (!resolvedAddress) {
          throw new Error("Unable to resolve ENS name to an Ethereum address");
        }
        addStatusMessage(`Resolved ${address} to ${resolvedAddress}`);
      }

      addStatusMessage("Establishing secure connection to the blockchain...");
      await delay(1000);
      addStatusMessage("Synchronizing with the latest block...");
      await delay(1000);

      // Fetch the latest block number
      const latestBlock = await fetchWithRetry(() => alchemy.core.getBlockNumber());
      
      addStatusMessage("Preparing to dive into the transaction pool...");
      await delay(1000);

      let incomingTransactions = [];
      let outgoingTransactions = [];
      let pageCount = 0;

      // Start from the earliest possible block (genesis block)
      let startBlock = 0;
      let endBlock = latestBlock;
      let oldestFetchedBlock = latestBlock;
      
      // Fetch incoming transactions
      while (incomingTransactions.length < MAX_TRANSACTIONS) {
        pageCount++;
        addStatusMessage(`Fetching batch ${pageCount} of incoming transactions...`);

        const response = await fetchWithRetry(() => alchemy.core.getAssetTransfers({
          fromBlock: `0x${startBlock.toString(16)}`,
          toBlock: `0x${endBlock.toString(16)}`,
          toAddress: resolvedAddress,
          category: ["external", "internal", "erc20", "erc721", "erc1155"],
          maxCount: BATCH_SIZE,
          order: 'asc'
        }));

        if (response.transfers.length === 0) break;

        incomingTransactions = [...incomingTransactions, ...response.transfers];
        addStatusMessage(`Fetched ${response.transfers.length} incoming transactions. Total: ${incomingTransactions.length}`);

        oldestFetchedBlock = Math.min(oldestFetchedBlock, parseInt(response.transfers[0].blockNum, 16));
        startBlock = parseInt(response.transfers[response.transfers.length - 1].blockNum, 16) + 1;
      }

      // Reset for outgoing transactions
      startBlock = 0;
      pageCount = 0;

      // Fetch outgoing transactions
      while (outgoingTransactions.length < MAX_TRANSACTIONS) {
        pageCount++;
        addStatusMessage(`Fetching batch ${pageCount} of outgoing transactions...`);

        const response = await fetchWithRetry(() => alchemy.core.getAssetTransfers({
          fromBlock: `0x${startBlock.toString(16)}`,
          toBlock: `0x${endBlock.toString(16)}`,
          fromAddress: resolvedAddress,
          category: ["external", "internal", "erc20", "erc721", "erc1155"],
          maxCount: BATCH_SIZE,
          order: 'asc'
        }));

        if (response.transfers.length === 0) break;

        outgoingTransactions = [...outgoingTransactions, ...response.transfers];
        addStatusMessage(`Fetched ${response.transfers.length} outgoing transactions. Total: ${outgoingTransactions.length}`);

        oldestFetchedBlock = Math.min(oldestFetchedBlock, parseInt(response.transfers[0].blockNum, 16));
        startBlock = parseInt(response.transfers[response.transfers.length - 1].blockNum, 16) + 1;
      }

      addStatusMessage("Compiling wallet history...");
      const allTransactions = [...incomingTransactions, ...outgoingTransactions];
      const formattedTxs = formatTransactions(allTransactions, resolvedAddress);

      addStatusMessage("Counting NFT transactions...");
      const nftTransactionCount = formattedTxs.filter(tx => 
        tx.category === 'erc721' || tx.category === 'erc1155'
      ).length;

      addStatusMessage("Calculating statistics...");
      const stats = await calculateStats(formattedTxs, nftTransactionCount, resolvedAddress);
      setTransactions(formattedTxs);
      setStats(stats);
      setNftCount(nftTransactionCount);

      addStatusMessage("Fetching earliest block timestamp...");
      const earliestBlock = await alchemy.core.getBlock(oldestFetchedBlock);
      const addressAge = calculateAddressAge(earliestBlock.timestamp);
      setAddressAge(addressAge);

      addStatusMessage("Starting wallet analysis...");
      const analysis = await analyzeWallet(stats);
      setAnalysisResult(analysis);
      addStatusMessage("Analysis complete!");

      addStatusMessage("Transaction fetch complete!");
      setAnalysisComplete(true);
    } catch (err) {
      setError(err.message);
      addStatusMessage(`Error: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const analyzeWallet = async (walletStats) => {
    addStatusMessage("Preparing data for analysis...");
    // Prepare the data for Cohere API
    const prompt = `Analyze the following Ethereum wallet summary and provide a brief, insightful comment (2-3 sentences) along with a score out of 1,000,000 based on the wallet's activity. The score should reflect the wallet's activity level, with higher scores for more active wallets. For reference:
    - A wallet with 0-10 transactions should score below 10,000
    - A wallet with 100-1,000 transactions should score around 100,000-300,000
    - A wallet with over 10,000 transactions should score above 700,000
    
    Additionally, if the wallet shows a profit (positive profitLossPercentage), add a small bonus to the score (up to 10% extra). Losses should not affect the score negatively. Note that the profitLossPercentage can be '∞' (infinite profit) or '-∞' (infinite loss) in some cases.

    Total Transactions: ${walletStats.totalTransactions}
    Incoming Transactions: ${walletStats.incomingTransactions}
    Outgoing Transactions: ${walletStats.outgoingTransactions}
    Total ETH In: ${walletStats.totalEthIn}
    Total ETH Out: ${walletStats.totalEthOut}
    Current ETH Balance: ${walletStats.currentEthBalance}
    NFT Transactions: ${walletStats.nftTransactions}
    Address Age: ${addressAge}
    Rough Profit/Loss: ${walletStats.roughProfitLoss} ETH
    Profit/Loss Percentage: ${walletStats.profitLossPercentage}

    Provide the response in the following format:
    Score: [score]
    Analysis: [Your concise analysis here]`;

    addStatusMessage("Analyzing transaction history...");
    try {
      const response = await fetch('https://api.cohere.ai/v1/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_COHERE_API_KEY}`
        },
        body: JSON.stringify({
          model: 'command',
          prompt: prompt,
          max_tokens: 150,
          temperature: 0.7,
          k: 0,
          stop_sequences: [],
          return_likelihoods: 'NONE'
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const generatedText = data.generations[0].text.trim();

      const scoreMatch = generatedText.match(/Score:\s*(\d{1,3}(?:,\d{3})*)/);
      const analysisMatch = generatedText.match(/Analysis:\s*([\s\S]+)/);

      if (scoreMatch && analysisMatch) {
        addStatusMessage("Processing analysis results...");
        return {
          score: parseInt(scoreMatch[1].replace(/,/g, '')),
          analysis: analysisMatch[1].trim()
        };
      } else {
        throw new Error('Failed to parse score and analysis from Cohere API response');
      }
    } catch (err) {
      console.error("Error in wallet analysis:", err);
      addStatusMessage(`Error in analysis: ${err.message}`);
      return null;
    }
  };

  const safeFormatEther = (value) => {
    if (typeof value === 'object' && value.hex) {
      return formatEther(BigInt(value.hex));
    }
    if (typeof value === 'string' && value.startsWith('0x')) {
      return formatEther(value);
    }
    if (typeof value === 'bigint') {
      return formatEther(value);
    }
    if (typeof value === 'number' || typeof value === 'string') {
      return value.toString();
    }
    return '0';
  };

  const checkForAirdrops = () => {
    addStatusMessage("Checking for eligible airdrops...");
    setTimeout(() => {
      addStatusMessage("No available airdrops found. Check back another time.");
    }, 2000);
  };

  return (
    <div className="min-h-screen bg-gray-950 text-gray-300 p-4">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <Logo />
          <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-purple-300">Wallet Analysis</h1>
        </div>

        <div className="form-control mb-8">
          <div className="input-group mb-4">
            <input
              type="text"
              placeholder="Enter ETH address or ENS name"
              className="input input-bordered w-full px-4 py-2 rounded-lg bg-gray-800 text-gray-300 border border-purple-800 focus:outline-none focus:border-purple-500"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="flex justify-between">
            <button 
              className="btn px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition duration-300 ease-in-out flex-grow mr-2 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={fetchTransactions}
              disabled={isLoading}
            >
              <span>{isLoading ? 'Analyzing...' : 'Analyze'}</span>
            </button>
            <button 
              className="btn px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition duration-300 ease-in-out flex-grow ml-2 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={checkForAirdrops}
              disabled={true}
            >
              <span>Check for Airdrops</span>
            </button>
          </div>
        </div>
        
        <div className="bg-gray-900 rounded-lg p-4 md:p-6 mb-8 shadow-lg border border-purple-800">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-4">
            <StatCard title="Total Tx" value={stats?.totalTransactions} />
            <StatCard title="In/Out" value={stats ? `${stats.incomingTransactions}/${stats.outgoingTransactions}` : null} />
            <StatCard title="ETH In/Out" value={stats ? `${stats.totalEthIn}/${stats.totalEthOut}` : null} />
            <StatCard title="Address Age" value={addressAge} />
            <StatCard 
              title="Est. P/L" 
              value={stats?.profitLossPercentage} 
              isPercentage={true}
              isPositive={stats?.profitLossPercentage === '∞' || parseFloat(stats?.profitLossPercentage) >= 0}
            />
            <StatCard title="NFT Tx" value={stats?.nftTransactions} />
          </div>
        </div>

        <div className="mt-4 p-6 bg-gray-900 rounded-lg animate-fade-in border border-purple-800 mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-purple-300">Analysis Result</h2>
          {analysisResult ? (
            <>
              <p className="text-xl mb-2">Score: <span className="font-bold text-purple-400">{analysisResult.score.toLocaleString()}</span></p>
              <p className="text-lg text-gray-400">{analysisResult.analysis}</p>
            </>
          ) : (
            <>
              <div className="h-8 bg-gray-800 rounded animate-pulse mb-2"></div>
              <div className="h-20 bg-gray-800 rounded animate-pulse"></div>
            </>
          )}
        </div>
        
        {isLoading && (
          <div className="mt-8 flex flex-col items-center">
            <div className="w-24 h-24 rounded-full border-4 border-purple-600 border-t-transparent animate-spin-pulse"></div>
          </div>
        )}
        
        {error && <div className="alert alert-error mt-4 bg-red-900 text-gray-300">{error}</div>}

        {snackbarMessage && (
          <Snackbar 
            message={snackbarMessage} 
            onClose={() => setSnackbarMessage('')}
          />
        )}
      </div>
    </div>
  );
}

function StatCard({ title, value, isPercentage = false, isPositive = true }) {
  return (
    <div className="bg-gray-800 p-3 md:p-4 rounded-lg shadow">
      <h3 className="text-xs sm:text-sm md:text-lg font-semibold mb-1 md:mb-2 text-purple-300">{title}</h3>
      {value === null || value === undefined ? (
        <div className="h-4 md:h-6 bg-gray-700 rounded animate-pulse"></div>
      ) : (
        <p className={`text-xs sm:text-sm md:text-xl font-bold truncate ${isPercentage ? (isPositive ? 'text-green-500' : 'text-red-500') : 'text-gray-300'}`}>
          {isPercentage && value !== '∞' && value !== '-∞' ? `${value}%` : value}
        </p>
      )}
    </div>
  );
}

function Logo() {
  return (
    <div className="flex items-center">
      <LogoSVG className="w-8 h-8 md:w-10 md:h-10 mr-2" />
      <span className="text-sm sm:text-lg md:text-xl font-bold text-purple-300">MAGMA SENDER</span>
    </div>
  );
}

export default App;
