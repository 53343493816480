import React, { useState } from 'react';
import Header from './Header';

function WaitlistPage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch('/waitlist.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      setMessage(data.message);
      if (data.success) {
        setEmail('');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
    setIsSubmitting(false);
  };

  return (
    <div className="min-h-screen bg-gray-950 text-gray-300">
      <Header />
      <div className="max-w-4xl mx-auto p-4">
        <h1 className="text-4xl font-bold text-purple-300 mb-8 text-center">Join the Airdrop Waitlist</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-400 mb-2">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="Enter your email"
            />
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out disabled:opacity-50"
          >
            {isSubmitting ? 'Joining...' : 'Join Waitlist'}
          </button>
        </form>
        {message && (
          <div className={`mt-4 p-4 rounded-lg ${message.includes('Error') ? 'bg-red-800' : 'bg-green-800'}`}>
            {message}
          </div>
        )}
      </div>
    </div>
  );
}

export default WaitlistPage;