import React, { useState } from 'react';
import Header from './Header';

function TokenDesigner() {
  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [tokenSupply, setTokenSupply] = useState('');
  const [tokenDecimals, setTokenDecimals] = useState('18');
  const [tokenImage, setTokenImage] = useState(null);
  const [network, setNetwork] = useState('ethereum');
  const [isMintable, setIsMintable] = useState(false);
  const [isBurnable, setIsBurnable] = useState(false);
  const [isPausable, setIsPausable] = useState(false);
  const [hasTaxes, setHasTaxes] = useState(false);
  const [buyTax, setBuyTax] = useState('0');
  const [sellTax, setSellTax] = useState('0');

  const networks = [
    { id: 'ethereum', name: 'Ethereum', disabled: false },
    { id: 'solana', name: 'Solana', disabled: true },
    { id: 'binance', name: 'Binance Smart Chain', disabled: true },
    { id: 'polygon', name: 'Polygon', disabled: true },
    { id: 'avalanche', name: 'Avalanche', disabled: true },
  ];

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setTokenImage(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically handle the token creation
    console.log('Token creation submitted', {
      name: tokenName,
      symbol: tokenSymbol,
      supply: tokenSupply,
      decimals: tokenDecimals,
      network,
      isMintable,
      isBurnable,
      isPausable,
      hasTaxes,
      buyTax,
      sellTax,
    });
  };

  return (
    <div className="min-h-screen bg-gray-950 text-gray-300">
      <Header />
      <div className="max-w-4xl mx-auto p-4">
        <h1 className="text-4xl font-bold text-purple-300 mb-8 text-center">Token Designer</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">Network</label>
            <select
              value={network}
              onChange={(e) => setNetwork(e.target.value)}
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
            >
              {networks.map((net) => (
                <option key={net.id} value={net.id} disabled={net.disabled}>
                  {net.name}
                </option>
              ))}
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">Token Name</label>
            <input
              type="text"
              value={tokenName}
              onChange={(e) => setTokenName(e.target.value)}
              required
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="e.g., My Awesome Token"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">Token Symbol</label>
            <input
              type="text"
              value={tokenSymbol}
              onChange={(e) => setTokenSymbol(e.target.value)}
              required
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="e.g., MAT"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">Total Supply</label>
            <input
              type="number"
              value={tokenSupply}
              onChange={(e) => setTokenSupply(e.target.value)}
              required
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="e.g., 1000000"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">Decimals</label>
            <input
              type="number"
              value={tokenDecimals}
              onChange={(e) => setTokenDecimals(e.target.value)}
              required
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="e.g., 18"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">Token Image</label>
            <input
              type="file"
              onChange={handleImageUpload}
              accept="image/*"
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>

          <div className="flex flex-wrap gap-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={isMintable}
                onChange={(e) => setIsMintable(e.target.checked)}
                className="form-checkbox h-5 w-5 text-purple-600"
              />
              <span className="ml-2 text-gray-300">Mintable</span>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={isBurnable}
                onChange={(e) => setIsBurnable(e.target.checked)}
                className="form-checkbox h-5 w-5 text-purple-600"
              />
              <span className="ml-2 text-gray-300">Burnable</span>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={isPausable}
                onChange={(e) => setIsPausable(e.target.checked)}
                className="form-checkbox h-5 w-5 text-purple-600"
              />
              <span className="ml-2 text-gray-300">Pausable</span>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={hasTaxes}
                onChange={(e) => setHasTaxes(e.target.checked)}
                className="form-checkbox h-5 w-5 text-purple-600"
              />
              <span className="ml-2 text-gray-300">Enable Taxes</span>
            </label>
          </div>

          {hasTaxes && (
            <div className="flex gap-4">
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-400 mb-2">Buy Tax (%)</label>
                <input
                  type="number"
                  value={buyTax}
                  onChange={(e) => setBuyTax(e.target.value)}
                  className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
                  placeholder="e.g., 5"
                />
              </div>
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-400 mb-2">Sell Tax (%)</label>
                <input
                  type="number"
                  value={sellTax}
                  onChange={(e) => setSellTax(e.target.value)}
                  className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
                  placeholder="e.g., 5"
                />
              </div>
            </div>
          )}

          <button
            type="submit"
            className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out"
          >
            Create Token
          </button>
        </form>
      </div>
    </div>
  );
}

export default TokenDesigner;