import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import { blogPosts } from './Blog';
import { ReactComponent as LogoSVG } from './logo.svg';
import SupportedNetworks from './SupportedNetworks';  // Import the new component

function HomePage() {
  const [count, setCount] = useState(5000);
  const targetCount = 6419;
  const duration = 500;

  useEffect(() => {
    let start = 6000;
    const end = targetCount;
    const incrementTime = duration / (end - start);
    let timer = setInterval(() => {
      start += 1;
      setCount(start);
      if (start === end) clearInterval(timer);
    }, incrementTime);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="min-h-screen bg-gray-950 text-gray-300">
      <div className="bg-purple-800 text-white py-2 px-4 text-center">
        <Link to="/wallet-analysis" className="hover:underline">
          New: Try our advanced wallet analyzer tool - Get insights now!
        </Link>
      </div>
      <Header />
      <div className="max-w-6xl mx-auto p-4">
        <main>
          <section className="text-center mb-16">
            <h1 className="text-5xl md:text-6xl font-bold mb-4 shimmer-text">MAGMA SENDER</h1>
            <p className="text-xl md:text-2xl mb-4 text-purple-400">Comprehensive cryptocurrency and blockchain tools</p>
            <p className="text-2xl md:text-3xl mb-12 text-purple-300">
              <span className="font-bold text-4xl md:text-5xl">{count.toLocaleString()}</span> Airdrops delivered
            </p>
            <Link to="/wallet-analysis" className="bg-gradient-to-r from-purple-600 to-pink-500 text-white px-8 py-4 rounded-lg hover:from-purple-700 hover:to-pink-600 transition duration-300 text-lg font-semibold mb-8 inline-block">Analyze Wallet</Link>
            
            {/* Add SupportedNetworks component here */}
            <div className="mt-12">
              <SupportedNetworks />
            </div>
          </section>

          <section id="tools" className="mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-purple-300 mb-6 text-center">Our Tools</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <Link to="/token-designer">
                <ToolCard
                  title="1. Token Designer"
                  description="No-code custom token deployer with customizable hosted token site/dapp"
                  icon={<TokenIcon />}
                  bgColor="bg-purple-800"
                />
              </Link>
              <ToolCard
                title="2. Wallet Analysis"
                description="Identify potential recipients based on deep analysis of wallet address history"
                icon={<AnalysisIcon />}
                bgColor="bg-indigo-800"
              />
              <ToolCard
                title="3. Airdrop Portal"
                description="Host a claimable token portal or set up distribution criteria"
                icon={<AirdropIcon />}
                bgColor="bg-blue-800"
              />
              <ToolCard
                title="4. Bulk Token Sender"
                description="Send tokens to up to 100,000 addresses in one transaction"
                icon={<BulkSendIcon />}
                bgColor="bg-cyan-800"
              />
            </div>
          </section>

          <section className="mb-16 bg-gradient-to-r from-purple-900 to-indigo-900 rounded-lg p-8 shadow-xl">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-6 text-center">Airdrop with Confidence</h2>
            <p className="text-xl text-center mb-8">
              Need to airdrop to your holders? We've got <span className="font-bold text-purple-300">ALL</span> the tools for a successful campaign.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <FeatureCard
                title="Token Designer"
                description="Create custom tokens with specific parameters"
                icon="🎨"
              />
              <FeatureCard
                title="Airdrop Portal"
                description="Set distribution criteria and manage recipient lists"
                icon="🚀"
              />
              <FeatureCard
                title="Bulk Token Sender"
                description="Efficiently send tokens to thousands of addresses"
                icon="📦"
              />
              <FeatureCard
                title="Wallet Analysis"
                description="Identify potential recipients based on on-chain activity"
                icon="🔍"
              />
            </div>
            <div className="text-center mt-8">
              <Link to="/waitlist" className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105">
                Join Airdrop Waitlist
              </Link>
            </div>
          </section>

          {/* Add this new section for blog posts */}
          <section id="latest-posts" className="mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-purple-300 mb-6 text-center">Latest Blog Posts</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {blogPosts.map(post => (
                <div key={post.id} className="bg-gray-800 rounded-lg p-6 shadow-lg">
                  <h3 className="text-xl font-semibold text-purple-300 mb-2">
                    <Link to={`/blog/${post.slug}`} className="hover:underline">{post.title}</Link>
                  </h3>
                  <p className="text-gray-400 mb-4">{post.excerpt}</p>
                  <p className="text-sm text-gray-500">{post.date}</p>
                </div>
              ))}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}

function ToolCard({ title, description, icon, bgColor }) {
  return (
    <div className={`${bgColor} rounded-lg p-6 shadow-lg border border-purple-600 hover:border-purple-400 transition duration-300 ease-in-out transform hover:-translate-y-1 flex flex-col items-center text-center cursor-pointer`}>
      <div className="mb-4 text-white w-16 h-16">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
      <p className="text-gray-200">{description}</p>
    </div>
  );
}

function AirdropIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
    </svg>
  );
}

function TokenIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 11V9a2 2 0 00-2-2m2 4v4a2 2 0 104 0v-1m-4-3H9m2 0h4m6 1a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  );
}

function BulkSendIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 11l7-7 7 7M5 19l7-7 7 7" />
    </svg>
  );
}

function AnalysisIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
    </svg>
  );
}

function Logo() {
  return (
    <div className="flex items-center">
      <LogoSVG className="w-10 h-10 mr-2" />
      <span className="text-xl font-bold text-purple-300">MAGMA SENDER</span>
    </div>
  );
}

function FeatureCard({ title, description, icon }) {
  return (
    <div className="bg-gray-800 bg-opacity-50 rounded-lg p-6 shadow-lg hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1">
      <div className="text-4xl mb-4">{icon}</div>
      <h3 className="text-xl font-semibold text-purple-300 mb-2">{title}</h3>
      <p className="text-gray-300">{description}</p>
    </div>
  );
}

export default HomePage;