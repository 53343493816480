import React from 'react';

const networks = [
    { name: 'Ethereum', logo: '/imgs/ethereum-eth-logo.svg' },
    { name: 'Solana', logo: '/imgs/solana-sol-logo.svg' },
    { name: 'Optimism', logo: '/imgs/optimism-ethereum-op-logo.svg' },
    { name: 'BNB Chain', logo: '/imgs/bnb-bnb-logo.svg' },
    { name: 'TON', logo: '/imgs/toncoin-ton-logo.svg' },
];

function SupportedNetworks() {
    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -my-6">
            <div className="flex flex-wrap justify-center items-center">
                {networks.map((network) => (
                    <div key={network.name} className="p-2">
                        <img 
                            src={network.logo} 
                            alt={`${network.name} logo`} 
                            className="w-12 h-12 object-contain"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SupportedNetworks;