import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import Footer from './Footer';  // Updated import
import WalletAnalysis from './WalletAnalysis';
import Blog from './Blog';
import BlogPost from './BlogPost';
import WaitlistPage from './WaitlistPage';
import TokenDesigner from './TokenDesigner';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/wallet-analysis" element={<WalletAnalysis />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/waitlist" element={<WaitlistPage />} />
            <Route path="/token-designer" element={<TokenDesigner />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

// Simple NotFound component
function NotFound() {
  return (
    <div className="min-h-screen bg-gray-950 text-gray-300 flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-purple-300 mb-4">404 - Page Not Found</h1>
        <p className="mb-4">The page you are looking for doesn't exist or has been moved.</p>
        <a href="/" className="text-purple-300 hover:underline">Go back to home</a>
      </div>
    </div>
  );
}

export default App;