import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from './logo.svg';

function Header() {
  const [isToolsDropdownOpen, setIsToolsDropdownOpen] = useState(false);

  return (
    <header className="bg-gray-900 text-white py-4">
      <div className="container mx-auto flex justify-between items-center px-4">
        <Link to="/" className="flex items-center">
          <LogoSVG className="w-8 h-8 mr-2" />
          <span className="text-xl font-bold">MAGMA SENDER</span>
        </Link>
        <nav>
          <ul className="flex space-x-4">
            <li><Link to="/" className="hover:text-purple-300">Home</Link></li>
            <li className="relative">
              <button 
                onClick={() => setIsToolsDropdownOpen(!isToolsDropdownOpen)}
                className="hover:text-purple-300"
              >
                Tools
              </button>
              {isToolsDropdownOpen && (
                <ul className="absolute left-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg py-1 z-10">
                  <li>
                    <Link 
                      to="/token-designer" 
                      className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700"
                      onClick={() => setIsToolsDropdownOpen(false)}
                    >
                      Token Designer
                    </Link>
                  </li>
                  {/* Add more tool links here */}
                </ul>
              )}
            </li>
            <li><Link to="/blog" className="hover:text-purple-300">Blog</Link></li>
            <li><Link to="/waitlist" className="hover:text-purple-300">Waitlist</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;